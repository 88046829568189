<template>
  <div class="root">
    <van-cell-group title="投资风险取向">
      <van-cell class="content">
        <div class="title theme-color">
          <span>{{ title }}</span>
        </div>
        <div class="theme-color" v-html="content" />
      </van-cell>
    </van-cell-group>
    <div style="padding: 16px" class="theme-color">A部分 年期与避险</div>
    <div v-for="(item, index) in form['A部分 年期与避险']" :key="item.title">
      <van-cell-group>
        <template #title>
          <div class="theme-color">{{ index + 1 + ". " + item.title }}</div>
          <div
            style="margin-top: 10px;"
            v-if="item.other_info"
            class="border-left border-top border-right"
          >
            <div
              class="info border-bottom theme-color"
              v-for="(it, idx) in item.other_info"
              :key="idx"
              :style="idx === 0 ? 'background-color: rgba(0,0,0,0.1)' : ''"
            >
              <div
                v-for="(i, d) in it"
                :class="d > 0 ? 'info-item border-left' : 'info-item'"
                :key="i"
              >
                {{ i }}
              </div>
            </div>
          </div>
        </template>
        <van-radio-group class="check-group" v-model="item.choiced[0]">
          <van-radio v-for="(it, id) in item.options" :key="it" :name="id" icon-size="24px">
            <van-cell>{{ it }}</van-cell>
          </van-radio>
        </van-radio-group>
      </van-cell-group>
    </div>
    <div style="padding: 16px" class="theme-color">投资风险取向自评</div>
    <div v-for="item in form['投资风险取向自评']" :key="item.title">
      <van-cell-group>
        <template #title>
          <div>{{ item.title }}</div>
        </template>
        <van-radio-group class="check-group" v-model="item.choiced[0]">
          <van-radio v-for="(it, id) in item.options" :key="it" :name="id" icon-size="24px">
            <van-cell>{{ it }}</van-cell>
          </van-radio>
        </van-radio-group>
      </van-cell-group>
    </div>
    <div style="padding: 16px" class="theme-color">B部分</div>
    <div v-for="(item, index) in form['B部分']" :key="item.title">
      <van-cell-group :title="item.title">
        <template #title>
          <div>{{ index + 1 + ". " + item.title }}</div>
        </template>
        <van-radio-group
          v-if="item.type === 'single'"
          class="check-group"
          v-model="item.choiced[0]"
        >
          <van-radio v-for="(it, id) in item.options" :key="it" :name="id" icon-size="24px">
            <van-cell>{{ it }}</van-cell>
          </van-radio>
        </van-radio-group>
        <van-checkbox-group
          v-if="item.type === 'multiple'"
          class="check-group"
          v-model="item.choiced"
        >
          <van-checkbox
            v-for="(it, id) in item.options"
            :key="it"
            :name="id"
            icon-size="24px"
            shape="square"
            style="padding-left: 16px;"
          >
            <van-cell>{{ it }}</van-cell>
          </van-checkbox>
        </van-checkbox-group>
      </van-cell-group>
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        提交
      </van-button>
      <van-button class="btn" @click="before" :class="isYearlyRisk ? 'hide' : ''">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, CellGroup, Cell, RadioGroup, Radio, CheckboxGroup, Checkbox } from "vant";
import { useStore } from "vuex";
import { onMounted, reactive, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import httpApi from "../../utils/httpApi";
import httpAddApi from "../../utils/httpAddApi";

const title = "责任声明";
const content = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此问卷结果根据您所提供的资料而制定，并只供您作个人投资决定的参考。此问卷内容及结果不可视为对任何投资产品及服务的销售或购买邀请，亦不应当为投资建议。金洛证券对上述有关资料的准确性及完整性并不负上任何责任。金洛证券确保此问卷内的个人资料得到保密。阁下提供的资料只会在保密的情况下，按我们已交付予阁下的有关开户文件资料内随附的私隐政策所载，供金洛证券或其所属的集团公司内的任何成员公司使用。

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此问卷是为评估您的总体投资风险的取向而设。此评估只供参考之用，不一定准确反映您真正的风险取向和风险承受能力。金洛证券有限公司对于此评估及此问卷内获得资料的准确性不会负责或承担任何责任。
`.replace(/\r?\n/g, "<br />");

export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data() {
    return {};
  },
  methods: {
    async next() {
      if (this.isYearlyRisk && this.$store.state.user.skipSubmit) {
        this.$router.push({ path: '/riskOrientation/step_two' });
        return;
      }
      if (this.disabledNext.value) return;
      const params = JSON.parse(JSON.stringify(this.form));
      // 默认每年风险评估
      let httprequest;
      if (this.isYearlyRisk) {
        httprequest = httpApi.postYearlyRiskTropism;
      } else {
        httprequest = this.isAddAccount
          ? httpAddApi.updateRiskTropism
          : httpApi.postRiskOrientationStepOne;
      }
      const result = await httprequest(params);
      if (result) {
        if (this.isAddAccount) await this.$api.submitLog({ type: "submitAddRiskTropism" });
        if (this.isYearlyRisk) await this.$api.submitLog({ type: "submitYearlyRiskTropism" });
        this.$router.push({ path: "/riskOrientation/step_two" });
      }
    },
    before() {
      this.$router.back();
    }
  },
  watch: {
    // eslint-disable-next-line func-names, space-before-function-paren
    "form.B部分.2.choiced": function(value) {
      const choiced = Array.prototype.slice.call(value);
      const isHas5 = choiced.indexOf(5) !== -1;
      const { length } = choiced;
      if (!isHas5) return;
      if (length === 1) return;
      const last = choiced[length - 1];
      if (last === 5) {
        this.form.B部分[2].choiced = [5];
      } else {
        this.form.B部分[2].choiced = [last];
      }
    }
  },
  setup() {
    const form = reactive({
      "A部分 年期与避险": [],
      B部分: [],
      投资风险取向自评: []
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    const isYearlyRisk = computed(() => store.state.user.isYearlyRisk);
    onMounted(async () => {
      const route = useRoute();
      store.commit("setTitleValue", "风险认知");
      if (isYearlyRisk.value) {
        store.commit("setStepsShow", false);
        await httpApi.submitLog({ type: "yearlyOpenAccountLogin" });
      } else if (isAddAccount.value) {
        store.commit("setStepsValue", ["风险认知(3/4)", "提交资料"]);
        store.commit("setStepsActive", 0);
      } else {
        store.commit("setStepsValue", ["填写资料", "风险认知(3/4)", "提交资料"]);
        store.commit("setStepsActive", 1);
      }
      // 默认每年风险评估
      let httprequest = httpApi.getYearlyRiskTropism;
      if (!isYearlyRisk.value) {
        httprequest = isAddAccount.value
          ? httpAddApi.getRiskTropism
          : httpApi.getRiskOrientationStepOne;
      }
      const result = await httprequest();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach(item => {
        if (result.data[item] !== undefined) {
          form[item] = result.data[item];
          if (route && route.params.clear) {
            result.data[item].forEach((_, index) => {
              form[item][index].choiced = [];
            });
          }
        }
      });
    });
    const disabledNext = ref(true);
    watch(form, value => {
      disabledNext.value = false;
      value["A部分 年期与避险"].forEach(item => {
        if (item.choiced.length === 0) {
          disabledNext.value = true;
        }
      });
      value["B部分"].forEach(item => {
        if (item.choiced.length === 0) {
          disabledNext.value = true;
        }
      });
      value["投资风险取向自评"].forEach(item => {
        if (item.choiced.length === 0) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(value, disabledNext);
    });
    return {
      form,
      title,
      isAddAccount,
      isYearlyRisk,
      content,
      disabledNext
    };
  }
};
</script>

<style lang="less">
.root {
  text-align: left;
  .content {
    margin-top: @space-2;
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }

  .title {
    text-align: center;
  }

  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
  .van-radio-group {
    .van-radio {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: @space-4 @space-3;
    }
    div:first-child {
      border-top: none;
    }
  }
  .info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .info-item {
      flex-basis: 30%;
      padding: @space-4;
      text-align: center;
    }
  }
  .border-top {
    border-top: 1px solid @font-color-1;
  }
  .border-right {
    border-right: 1px solid @font-color-1;
  }
  .border-bottom {
    border-bottom: 1px solid @font-color-1;
  }
  .border-left {
    border-left: 1px solid @font-color-1;
  }
  .hide {
    visibility: hidden;
  }
}
</style>
